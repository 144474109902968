import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const labLabelList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'checksticker/list',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.log('Get lab label list err : ', err)

      return {}
    })

  return response
}

export default { labLabelList }
