import labLabel from '@/api/drugAndCert/labLabel'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import { i18n } from '@/plugins/i18n'
import router from '@/router'
import { ref, watch } from '@vue/composition-api'

export default function useLabLabel() {
  const searchtext = ref('')
  const isShowDate = ref(false)
  const moment = require('moment')

  const opd_date = ref(moment(new Date()).format('YYYY-MM-DD'))

  const dataTableList = ref([])
  const totalDataTableList = ref(0)
  const totalPage = ref(0)
  const columns = ref([
    {
      text: '#',
      align: 'start',
      sortable: false,
      width: 30,
      value: 'opd_id',
    },
    {
      text: i18n.t('date'),
      value: 'opd_date',
      width: 130,
    },
    {
      text: `${i18n.t('a_number')} OPD`,
      value: 'opd_code',
      width: 130,
    },
    {
      text: `${i18n.t('lab_number')}`,
      value: 'code',
      width: 120,
    },
    {
      text: `${i18n.t('list')}`,
      value: 'datalist',
      width: 220,
    },
    {
      text: i18n.t('customer_patient'),
      value: 'customer_fullname',
      width: 160,
    },

    {
      text: i18n.t('option'),
      value: 'actions',
      width: 150,
      align: 'center',
    },
  ])
  const options = ref({})
  const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)
  const segmentId = ref(0)
  const loading = ref(false)

  const selected = ref([])

  const { labLabelList } = labLabel

  const fetchDataTable = () => {
    loading.value = true
    labLabelList({
      searchtext: searchtext.value,
      opd_date: opd_date.value,
      active_page: options.value.page,
      per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
    }).then(res => {
      const { data, count, segment, count_of_page } = res
      totalPage.value = count_of_page
      dataTableList.value = data
      totalDataTableList.value = count
      segmentId.value = segment
      loading.value = false
    })
  }

  watch([searchtext, opd_date, options], (newvalue, oldvalue) => {
    if (newvalue[0] !== oldvalue[0] || newvalue[1] !== oldvalue[1]) {
      options.value.page = 1
    }
    fetchDataTable()
  })

  const sendPrint = id => {
    const ids = []
    if (id) {
      ids.push(id)
    } else {
      selected.value.forEach(element => {
        ids.push(element.opd_id)
      })
    }
    const routeData = router.resolve({
      name: 'printLabLabel',
      query: {
        id: String(ids),
      },
    })
    window.open(routeData.href, '_blank')
  }

  return {
    selected,
    searchtext,
    isShowDate,
    opd_date,
    dataTableList,
    totalDataTableList,
    columns,
    options,
    totalPage,
    footer,
    segmentId,
    loading,
    sendPrint,
    fetchDataTable,
  }
}
