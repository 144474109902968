<template>
  <v-row class="px-2" align="center" justify="center">
    <v-col cols="12" md="4" class="d-flex justify-center justify-md-start">
      <v-text-field
        v-model="localitemsPerPage"
        :label="$t('countListPerPage')"
        type="number"
        outlined
        dense
        class="pt-1"
        style="max-width: 200px"
        hide-details
        @keypress.enter="changePage"
      ></v-text-field>
    </v-col>

    <v-col md="4" cols="12">
      <v-pagination v-model="localPage" color="primary" total-visible="6" :length="pageCount"></v-pagination>
    </v-col>

    <v-col md="4" cols="12" class="d-flex justify-end" align="center">
      <span v-if="countList > 0" class="text-sm font-weight-semibold pt-2">
        {{ $t('from') }} {{ segmentId + 1 }} {{ $t('to') }} {{ countTo }} {{ $t('of') }} {{ total }} {{ $t('list') }}
      </span>
    </v-col>
  </v-row>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { formatPrice } from '@/plugins/filters'

export default {
  filters: {
    formatPrice,
  },
  props: {
    page: { type: Number, default: 1 },
    pageCount: { type: Number, default: 1 },
    total: { type: Number, default: 0 },
    segmentId: { type: Number, default: 1 },
    countList: { type: Number, default: 1 },
    current: { type: String, default: '' },
  },

  setup(props, { emit }) {
    const localPage = ref(1)
    const localitemsPerPage = ref(10)
    const countTo = ref(0)
    watch(
      () => props.page,
      newPage => {
        localPage.value = JSON.parse(JSON.stringify(newPage))
      },
    )
    watch(
      () => props.segmentId,
      () => {
        countTo.value = +(props.segmentId + +props.countList)
      },
    )
    watch(
      () => props.countList,
      () => {
        countTo.value = +(props.segmentId + +props.countList)
      },
    )
    watch(localPage, value => {
      emit('pageChanged', value)
    })

    const changePage = () => {
      let max = 10000
      localitemsPerPage.value =
        localitemsPerPage.value > max ? max : localitemsPerPage.value <= 0 ? max : localitemsPerPage.value
      emit('itemChanged', localitemsPerPage.value)
      countTo.value = +(props.segmentId + +props.countList)
      localPage.value = 1
    }

    return {
      localPage,
      localitemsPerPage,
      changePage,
      countTo,
    }
  },
}
</script>
