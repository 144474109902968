<template>
  <div>
    <v-card>
      <v-card-title class='px-2'>
        {{ $t('Checksticker') }}
        <v-spacer></v-spacer>
        <v-btn
          color='primary'
          class='d-none d-md-block'
          :disabled='!selected.length'
          @click='sendPrint(null)'
        >
          <v-icon class='me-1'>
            {{ icons.mdiPrinter }}
          </v-icon>
          <span class='d-none d-sm-block'>{{ $t('printing_lab_labels') }}</span>
        </v-btn>
        <v-btn
          color='primary'
          fab
          outlined
          icon
          class='d-block d-md-none'
          :disabled='!selected.length'
          @click='sendPrint(null)'
        >
          <v-icon>
            {{ icons.mdiPrinter }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-row class='px-2'>
        <v-col
          cols='12'
          md='6'
          class='py-0'
          lg='4'
        >
          <v-text-field
            v-model='searchtext'
            :label="$t('search')"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col
          cols='12'
          md='6'
          class='py-0'
          lg='4'
        >
          <v-dialog
            ref='date'
            v-model.trim='isShowDate'
            :return-value.sync='opd_date'
            width='290px'
          >
            <template v-slot:activator='{ on, attrs }'>
              <v-text-field
                v-model='opd_date'
                outlined
                readonly
                dense
                :label="$t('date')"
                v-bind='attrs'
                v-on='on'
              >
                <template v-slot:append>
                  <v-tooltip
                    color='#212121'
                    top
                  >
                    <template v-slot:activator='{ on, attrs }'>
                      <v-icon
                        color='primary'
                        v-bind='attrs'
                        v-on='on'
                        @click="opd_date = ''"
                      >
                        {{ icons.mdiCalendarBlank }}
                      </v-icon>
                    </template>
                    <span>{{ $t('all_dates') }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              v-model.trim='opd_date'
              :locale='$i18n.locale'
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color='primary'
                @click='$refs.date.save(opd_date)'
              >
                {{ $t('confirm') }}
              </v-btn>
              <v-btn
                text
                color='primary'
                @click='isShowDate = false'
              >
                {{ $t('cancel') }}
              </v-btn>
              <v-spacer></v-spacer>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>
      <v-data-table
        v-model='selected'
        :headers='columns'
        :items='dataTableList'
        :options.sync='options'
        :server-items-length='totalDataTableList'
        :footer-props='footer'
        :loading='loading'
        disable-sort
        item-key='opd_id'
        hide-default-footer
        show-select
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.opd_id`]='{ index }'>
          {{ segmentId + index + 1 }}
        </template>
        <template v-slot:[`item.opd_date`]='{ item }'>
          <ConverseDate :date='item.opd_date' />
        </template>
        <template v-slot:[`item.datalist`]='{ item }'>
          <ul>
            <li v-for='list in item.datalist'>
              {{ `(${list.opdchecking_code}) ${list.opdchecking_name}` }}
            </li>
          </ul>
        </template>
        <template v-slot:[`item.actions`]='{ item }'>
          <v-tooltip
            color='#212121'
            top
          >
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                icon
                color='primary'
                v-bind='attrs'
                v-on='on'
                @click='sendPrint(item.opd_id)'
              >
                <v-icon>{{ icons.mdiPrinter }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('print') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <Pagination
        :page-data='options.page'
        :page-count='totalPage'
        :segment-id='+segmentId '
        :count-list='dataTableList.length'
        :total='+totalDataTableList'
        @pageChanged='(page)=>{options.page = page ; fetchDataTable()}'
        @itemChanged='(items)=>{options.itemsPerPage = items;options.page=1 ; fetchDataTable()}'
      />
    </v-card>
  </div>
</template>

<script>
import {
  mdiPlus, mdiPencilOutline,
  mdiDeleteOutline, mdiPrinter, mdiCheck, mdiCalendarBlank,
} from '@mdi/js'
import useLabLabel from './useLabLabel'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'
import { formatPrice } from '@/plugins/filters'
import Pagination from '@/components/basicComponents/Pagination.vue'

export default {
  components: {
    ConverseDate,
    Pagination,
  },
  filters: {
    formatPrice,
  },
  setup() {
    return {
      ...useLabLabel(),
      icons: {
        mdiPlus, mdiPencilOutline, mdiDeleteOutline, mdiPrinter, mdiCheck, mdiCalendarBlank,
      },
    }
  },
}
</script>

<style lang='scss' scoped>

</style>
